.card-bio {
    padding: 25px 35px 0;
}
.card-bio:last-child {
    padding-bottom: 25px;
}

.card-bio__title {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin: 25px 0 0;
}
.card-bio__title:first-child {
    margin: 0;
}


.card-bio__text {
    font-size: 11px;
    line-height: 17px;
    color: #DCDCDC;
    margin: 10px 0 0;
}

.card-bio__text:first-child {
    margin: 0;
}