.card-info {
    padding: 25px 35px 0;
    color: #fff;
    text-align: center;
}

.card-info:last-child {
    padding-bottom: 25px;
}

.card-info__name {
    margin: 0;
    font-size: 25px;
    font-weight: 600;
}

.card-info__title {
    margin: 10px 0 0;
    color: #F3BF99;
    font-size: 14px;
    font-weight: 300;
}

.card-info__link {
    margin: 10px 0 0;
    color: #fff;
    font-size: 10px;
    font-weight: 300;
    text-decoration: none;
}
.card-info__link:hover {
    opacity: 0.5;
}

.card-info__actions {
    margin: 20px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-info__linkedIn {
    background: #5093E2;
    color: #fff;
}


.card-info__actions .BTN i {
    margin-right: 10px;
}