.card-socials {
    margin: 25px 0 0;
    padding: 20px 35px;
    background: #161619;
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.card-socials__item {

}

.card-socials__item a {
    color: #918E9B;
}

.card-socials__item a:hover {
    opacity: 0.5;
}

.card-socials__item i {
    font-size: 25px;
}