/* Default and Base styles for the app */

* {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  background: #23252C;

}

.BTN {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 6px;
  background: #fff;
  color: #374151;
  width: 115px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
}

.BTN:hover {
  opacity: 0.5;
}

#card {
  display: block;
  overflow: hidden;
  border-radius: 10px;
  width: 317px;
  margin: 50px auto;
  background: #1A1B21;
}